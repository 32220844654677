import React from "react";
import Editor from "./components/Editor";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Error from "./components/Error";

import Login from "./pages/Login";
import Amie from "./pages/Amie";
import Logout from "./pages/Logout";
import MicrosoftCallback from "./pages/MicrosoftCallback";
import Auth from "./components/Auth";
import Pricing from "./pages/Pricing";
import FreeUser from "./components/FreeUser";

const router = createBrowserRouter([
  {
    path: "/Auth",
    element: <Auth />,
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <Error />,
  },
  {
    path: "/auth0/microsoft/*",
    element: <MicrosoftCallback />,
    errorElement: <Error />,
  },
  {
    path: "/editor",
    element: <Editor />,
    errorElement: <Error />,
  },
  {
    path: "/freeuser",
    element: <FreeUser />,
    errorElement: <Error />,
  },
  {
    path: "/logout",
    element: <Logout />,
    errorElement: <Error />,
  },
  {
    path: "/",
    element: <Editor />,
    errorElement: <Error />,
  },

  {
    path: "/pricing",
    element: <Pricing />,
    errorElement: <Error />,
  },
]);

const App = () => {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
};

export default App;
