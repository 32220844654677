import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { Button } from "@mui/material";
// import { Auth } from "../components/Auth";
import { Link, useNavigate } from "react-router-dom";

function LoginDesign() {
  const [toggleHover, setToggleHover] = useState(false);
  const navigator = useNavigate();

  const handletoggleHover = () => {
    setToggleHover((prev) => !prev);
  };
  useEffect(() => {
    // localStorage.clear();
  });

  return (
    <div>
      {" "}
      <Row>
        <div
          style={{
            background: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
          >
            <div
              style={{
                marginTop: 10,
                marginRight: 5,
                fontSize: 40,
                fontWeight: "bold",
              }}
            >
              {" "}
              Amie{" "}
            </div>
            <div
              style={{
                color: "#FD7F20",
                marginTop: 27,
                marginRight: 16,
                marginLeft: 5,
                fontSize: 16,
                fontStyle: "italic",
              }}
            >
              An AI Assistant for Developers
            </div>
          </div>
          <div>
            <Button
              style={{ marginRight: 10 }}
              onClick={() => {
                window.open(
                  "mailto:srikanth.guttula@evolutyz.com?subject=SendMail&body=Description"
                );
              }}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </Row>
      <div style={{ padding: 60, marginTop: 30, background: "#fff" }}>
        <h3
          style={{
            textAlign: "center",
            marginBottom: 20,
            fontSize: 50,
            fontWeight: "bold",
          }}
        >
          Unlock Your Coding Potential with Amie
        </h3>
        <h3
          style={{
            textAlign: "center",
            marginBottom: 20,
            color: "#4fd1c5",
            fontSize: 50,
            fontWeight: "bold",
          }}
        >
          The Ultimate AI Companion
        </h3>

        <p style={{ textAlign: "center", margin: "0px 16%", color: "#797979" }}>
          We understand that your time and efforts are valuable, and that's why
          we've developed a tool that not only enhances your coding skills, but
          also saves you a significant amount of time. Just upload your code to
          the input box and let Amie work her magic.
        </p>
      </div>
      <div
        style={{
          margin: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: "#fff",
        }}
      >
        {/* <Row>
          <div>
            <div
              style={{
                margin: "30px",
                fontWeight: 500,
                border: "2px solid #354f7eff",
                textDecoration: "none",
                padding: "15px",
                background: "#000",
                textTransform: "uppercase",
                color: "#354f7eff",
                borderRadius: "26px",
                transition: "all 0.2s ease-in-out",
                display: "inline-block",
              }}
            >
              <Link
                to={"/login"}
                style={
                  toggleHover ? { color: "#8cb1f3ff" } : { color: "#4fd1c5" }
                }
                onMouseEnter={handletoggleHover}
                onMouseLeave={handletoggleHover}
              >
                Signin with Microsoft
              </Link>
            </div>
          </div>
        </Row>
        <Row>
          <div>
            <Button
              style={{
                width: 220,
                margin: "30px",
                fontWeight: 500,
                border: "2px solid #354f7eff",
                textDecoration: "none",
                padding: "15px",
                background: "#000",
                textTransform: "uppercase",
                color: "#8cb1f3ff",
                borderRadius: "26px",
                transition: "all 0.2s ease-in-out",
                display: "inline-block",
              }}
              onClick={() => {
                navigator("/auth", { replace: true });
              }}
            >
              AB
            </Button>
          </div>
        </Row> */}

        <Row>
          <div>
            <Button
              style={{
                width: 220,
                margin: "30px",
                fontWeight: 500,
                border: "2px solid #354f7eff",
                textDecoration: "none",
                padding: "15px",
                background: "#000",
                textTransform: "uppercase",
                color: "#8cb1f3ff",
                borderRadius: "26px",
                transition: "all 0.2s ease-in-out",
                display: "inline-block",
              }}
              onClick={() => {
                navigator("/auth");
              }}
            >
              Try it now
            </Button>
          </div>
        </Row>
        <Row></Row>
      </div>
    </div>
  );
}

export default LoginDesign;
