// import firebase from "firebase/app";
import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getDatabase } from "firebase/database";

export let environment = "PRE";

const firebaseConfig = firebase.initializeApp({
  // Your Firebase configuration here
  apiKey: "AIzaSyABCGfpapzaTsJbA76avf3fFmPYc5urfFM",
  authDomain: "amie-7741d.firebaseapp.com",
  projectId: "amie-7741d",
  storageBucket: "amie-7741d.appspot.com",
  messagingSenderId: "880832112986",
  appId: "1:880832112986:web:5576002b771ec285b52b63",
  measurementId: "G-1HTDQ325Y5",
  databaseURL: "https://amie-7741d-default-rtdb.firebaseio.com/",
});
//const app = initializeApp(firebaseConfig);

export const database = getDatabase();
export const auth = firebaseConfig.auth();
export const db = firebaseConfig.firestore();
export default firebaseConfig;
// firebase.iitializeApp(firebaseConfig);
// const auth = firebase.auth();
