import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import BarLoader from "react-spinners/BarLoader";

const Login = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigator = useNavigate();

  console.log("isAuthenticated: ", isAuthenticated);

  useEffect(() => {
    console.log("login====UE");
    if (!isAuthenticated) {
      instance.loginRedirect({
        scopes: ["User.Read"],
      });
    } else {
      navigator("/", { replace: true });
    }
  });

  return (
    <div
      style={{
        margin: 0,
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        justifyContent: "center",
      }}
    >
      <BarLoader color="#FD7F20" />
    </div>
  );
};

export default Login;
