import React from "react";
import Row from "react-bootstrap/Row";

const Footer = () => {
  return (
    <div>
      <footer
        style={{
          borderTop: "5px solid #ea9611",
          color: "#717171",
          marginTop: 20,
        }}
      >
        <Row>
          <div style={{ padding: 20 }}>
            <img
              src={require("../ec_inc.png")}
              alt="logo"
              className="img-inc"
              style={{ marginLeft: 15, width: 50, marginRight: 15 }}
            />
            <img
              src={require("../ec_inc500.png")}
              alt="logo"
              className="img-inc"
              style={{ width: 50, marginRight: 15 }}
            />
            <strong>Copyrights Evolutyz IT Services.</strong> All rights
            reserved.
          </div>
        </Row>
      </footer>
    </div>
  );
};

export default Footer;
