//import React, { useState } from 'react';
import React, { useState, useEffect, useRef } from "react";

// import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "@mui/material";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";
import { FaAddressBook, FaAngleDown } from "react-icons/fa";
import { BiCopy } from "react-icons/bi";
// import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ClipLoader from "react-spinners/ClipLoader";
import { red } from "@mui/material/colors";
import CopyToClipboard from "react-copy-to-clipboard";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import LoginDesign from "./LoginDesign";
import { auth } from "./FirebaseConfiguration";

import { getAuth } from "firebase/auth";
import { getDatabase, ref, child, get } from "firebase/database";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import Disclaimer from "./Disclaimer";
import { useSearchParams } from "react-router-dom";

export default function FreeUser() {
  const { instance } = useMsal();
  const navigatorr = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const [ExplainTextColor, setExplainTextColor] = useState("#000000");
  const [commentTextColor, setCommentTextColor] = useState("#198754");
  const [CommentTextLineColor, setCommentLineColor] = useState("#198754");
  const [explainLineColor, setExplainLineColor] = useState("#ddd");
  const [refactorTextColor, setRefactorTextColor] = useState("#000000");
  const [refactorTextline, setRefactorlineColor] = useState("#ddd");
  const [ctoTextColor, setCtoTextColor] = useState("#000000");
  const [ctoTextline, setCtolineColor] = useState("#ddd");
  // const [javaScriptTextColor, setJavaScriptTextColor] = useState('#000000');
  // const [javaScriptTextline, setHavaScriptlineColor] = useState('#ddd');
  // const [typeScriptTextColor, setTypeScpritTextColor] = useState('#000000');
  // const [TtypeScriptextline, setScpritlineColor] = useState('#ddd');
  const [getTextFromRibbon, setTextFromRibbon] = useState(
    "Add comments to the below code \n"
  );
  const [toggleColor, setToggleColor] = useState("#5A5A5A");

  const [result, setResult] = useState("");
  const [iCopied, setCopied] = useState(false);

  const [message, setMessage] = useState("");
  const [count, setCount] = useState(true);
  const [laungauge, setLaungauge] = useState("Langauge");
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  const [loadingInProgress, setLoading] = useState(Boolean);
  const [loadingBox, setBox] = useState(true);
  const [userName, setUsername] = useState("");
  const [searchParams] = useSearchParams();

  function GetUserDetails() {
    // To retrieve data from localStorage
    const userIdFromLocalStorage = localStorage.getItem("userId");
    const userEmailFromLocalStorage = localStorage.getItem("userEmail");

    console.log(
      "===== userIdFromLocalStorage data ",
      userIdFromLocalStorage + userEmailFromLocalStorage
    );

    const dbRef = ref(getDatabase());
    get(child(dbRef, `users/${userIdFromLocalStorage}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log("===DbData", snapshot.val());
          const val = snapshot.val();
          // JSON.stringify(val);
          console.log("UserEmail", val.email);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    GetUserDetails();
    console.log("==========loggedUser" + userName);
    if (iCopied && isAuthenticated) {
      console.log("Edit====UE");

      const timer = setTimeout(() => {
        console.log("This will run after 1 second!");
        //setCopied(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [iCopied]);

  const ConvertData = () => {
    setLoading(true);
    fetch("https://api.openai.com/v1/chat/completions", {
      method: "post",
      headers: {
        Authorization:
          "Bearer " + "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",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",

        messages: [
          {
            role: "user",
            // content:"Add comments to the below code \n" + message,
            content: getTextFromRibbon + message,
          },
        ],
        temperature: 0.7,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        console.log(data.choices[0].message.content);
        //setUsers(data)
        //return {respnse: data.choices[0].message.content}
        setLoading(false);
        setBox(false);

        setResult(data.choices[0].message.content);

        console.log("getTextFromRibbon======" + getTextFromRibbon);
      });
  };

  function CommentMyCode() {
    setCommentTextColor("#198754");
    setExplainLineColor("#ddd");
    setExplainTextColor("#000000");
    setCommentLineColor("#198754");
    setRefactorTextColor("#000");
    setRefactorlineColor("#ddd");
    setToggleColor("#5A5A5A");
    setCtoTextColor("#000");
    setCtolineColor("#DDD");
    setTextFromRibbon("Add comments to the below code \n");
    console.log(getTextFromRibbon);

    // console.log(`Selected text: ${window.getSelection().toString()}`);
  }

  const ExplainMyCode = (e) => {
    setExplainTextColor("#198754");
    setCommentLineColor("#ddd");
    setCommentTextColor("#000");
    setExplainLineColor("#198754");
    setRefactorTextColor("#000");
    setRefactorlineColor("#ddd");
    setToggleColor("#5A5A5A");
    setCtoTextColor("#000");
    setCtolineColor("#DDD");
    setTextFromRibbon("Expalin my code\n");
    console.log(getTextFromRibbon);
  };
  const handleSelect = (e) => {
    alert("This is for Premium users only");

    // if (getTextFromRibbon === "Convert my code to Java") {
    //   alert("This is for Premium users only");
    // } else {
    //   setToggleColor("#198754");
    //   setLaungauge(e.replace(/#/g, ""));
    //   setTextFromRibbon("Convert my code to " + e.replace(/#/g, ""));
    //   setExplainTextColor("#000");
    //   setCommentLineColor("#ddd");
    //   setCommentTextColor("#000");
    //   setExplainLineColor("#ddd");
    //   setRefactorTextColor("#000");
    //   setRefactorlineColor("#ddd");
    //   setCtoTextColor("#198754");
    //   setCtolineColor("198754");
    // }

    console.log(getTextFromRibbon);
  };

  function refactorMyCode() {
    setCommentTextColor("#000");
    setCommentLineColor("#ddd");
    setExplainLineColor("#ddd");
    setExplainTextColor("#000000");
    setRefactorTextColor("#198754");
    setRefactorlineColor("#198754");
    setToggleColor("#5A5A5A");
    setCtoTextColor("#000");
    setCtolineColor("DDD");
    setTextFromRibbon("Refactor my code");
    console.log(getTextFromRibbon);
    // console.log(`Selected text: ${window.getSelection().toString()}`);
  }
  function advancedMyCode() {
    alert("Comming soon");
  }
  function copyCode() {
    alert("Code copied");
  }

  const text = {
    height: 450,
    width: "100%",
    borderStyle: "none",
    outlineStyle: "none",
  };
  // sendEmail = () => {
  //   window.open("mailto:support@example.com?subject=SendMail&body=Description");
  // };
  const handleChange = (event) => {
    // 👇 Get input value from "event"
    console.log("hi", event.target.value.length);
    if (event.target.value.length > 0) {
      setCount(false);
    } else {
      setCount(true);
    }
    setMessage(event.target.value);
  };

  //   const copyToClipBoard = async copyMe => {
  //     const setCopySuccess(await navigator.clipboard.readText());
  //  };
  const [user, setUser] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return unsubscribe;
  }, []);

  const [LoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (user || isAuthenticated) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);

  return (
    <>
      {!LoggedIn ? (
        <LoginDesign />
      ) : (
        <div className="App" style={{ background: "#ddd" }}>
          <Row>
            <div
              style={{
                background: "#fff",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require("../thumbnail_Logo.png")}
                  style={{
                    marginTop: 10,
                    justifyContent: "left",
                    alignItems: "flex-start",
                    height: 50,
                    width: 250,
                  }}
                />
                <div
                  style={{
                    marginTop: 10,
                    marginRight: 5,
                    fontSize: 32,
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Amie{" "}
                </div>
                <div
                  style={{
                    color: "#FD7F20",
                    marginTop: 25,
                    marginRight: 16,
                    marginLeft: 5,
                    fontSize: 14,
                    fontStyle: "italic",
                  }}
                >
                  An AI Assistant for Developers
                </div>
              </div>
              <div>
                <Button
                  style={{
                    width: 220,
                    margin: "30px",
                    fontWeight: 500,
                    border: "2px solid #354f7eff",
                    textDecoration: "none",
                    padding: "15px",
                    background: "green",
                    textTransform: "uppercase",
                    color: "#fff",
                    borderRadius: "26px",
                    transition: "all 0.2s ease-in-out",
                    display: "inline-block",
                  }}
                  onClick={() => {
                    navigatorr("/pricing");
                  }}
                >
                  Get premium
                </Button>

                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    window.open(
                      "mailto:srikanth.guttula@evolutyz.com?subject=SendMail&body=Description"
                    );
                  }}
                >
                  Contact Us
                </Button>

                <Button
                  style={{ marginRight: 10, color: "red" }}
                  onClick={() => {
                    auth.signOut();
                    setUser(null);

                    navigatorr("/logout", { replace: true });
                  }}
                >
                  Logout
                </Button>
              </div>
            </div>
          </Row>
          <Row
            style={{
              margin: 15,
              background: "#fff",
              boxShadow: "rgb(131 131 131) 1px 4px 14px",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottomWidth: 1,
                borderBottomColor: "#ddd",
                borderBottomStyle: "solid",
              }}
            >
              <div
                style={{
                  display: "flex",
                  background: "#fff",
                  // borderBottomWidth: 1,
                  // borderBottomColor: "#ddd",
                  // borderBottomStyle: "solid",
                }}
              >
                <div
                  style={{ marginTop: 10, marginRight: 15, fontWeight: "bold" }}
                >
                  {" "}
                  Actions:{" "}
                </div>

                <div
                  onMouseUp={CommentMyCode}
                  style={{
                    marginTop: 10,
                    marginRight: 15,
                    fontWeight: 500,
                    color: commentTextColor,
                    borderBottomColor: CommentTextLineColor,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    cursor: "pointer",
                  }}
                >
                  Add Comments
                </div>
                <div
                  onMouseUp={ExplainMyCode}
                  style={{
                    marginTop: 10,
                    marginRight: 15,
                    fontWeight: 500,
                    color: ExplainTextColor,
                    borderBottomColor: explainLineColor,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  Explain{" "}
                </div>
                <div
                  onMouseUp={refactorMyCode}
                  style={{
                    marginTop: 10,
                    marginRight: 15,
                    fontWeight: 500,
                    color: refactorTextColor,
                    borderBottomColor: refactorTextline,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    cursor: "pointer",
                  }}
                >
                  Refactor{" "}
                </div>
                <div
                  onMouseUp={advancedMyCode}
                  style={{
                    marginTop: 10,
                    marginRight: 15,
                    fontWeight: 500,
                    color: "#000",
                    borderBottomColor: "#ddd",
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    cursor: "pointer",
                  }}
                >
                  Advanced{" "}
                </div>

                <div
                  style={{
                    marginTop: 10,
                    marginRight: 5,
                    fontWeight: 500,
                    color: ctoTextColor,
                    borderBottomColor: ctoTextline,
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                  }}
                >
                  Convert to other language
                </div>
                <Dropdown style={{ padding: 5 }} onSelect={handleSelect}>
                  <Dropdown.Toggle
                    style={{ backgroundColor: toggleColor }}
                    id="dropdown-basic"
                    variant="success"
                  >
                    {laungauge}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#Java">Java</Dropdown.Item>
                    <Dropdown.Item href="#Python">Python</Dropdown.Item>
                    <Dropdown.Item href="#C Sharp">C Sharp</Dropdown.Item>
                    <Dropdown.Item href="#SQL">SQL</Dropdown.Item>
                    <Dropdown.Item href="#JavaScript">JavaScript</Dropdown.Item>
                    <Dropdown.Item href="#TypeScript">TypeScript</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                {result.length == 0 ? null : (
                  <div>
                    <CopyToClipboard
                      text={result}
                      onCopy={() => {
                        if (!result.length == 0) {
                          setCopied(true);
                        }
                      }}
                    >
                      <BiCopy
                        style={{
                          height: 25,
                          marginTop: 10,
                          width: 25,
                          flex: 1,
                          marginLeft: "30%",
                          justifyContent: "flex-end",
                          cursor: "pointer",
                          color: iCopied ? "black" : "gray",
                        }}
                      ></BiCopy>
                    </CopyToClipboard>

                    {iCopied ? <span style={{ color: "green" }}></span> : null}
                  </div>
                )}
              </div>
            </div>
            <Col
              xs={6}
              style={{
                padding: 0,
                borderRightStyle: "solid",
                borderRightWidth: 3,
                borderRightColor: "#ddd",
              }}
            >
              <form style={{ position: "relative", textAlign: "center" }}>
                {" "}
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  style={text}
                  placeholder="Paste or upload your code here. Amie will do the magic !!! "
                  value={message}
                  onFocus={GetUserDetails}
                  onChange={handleChange}
                ></textarea>{" "}
                {message.length == 0 ? (
                  <div
                    style={{
                      position: "absolute",

                      top: "50%",

                      left: "50%",

                      transform: "translate(-50%, -50%)",

                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setTimeout(async () => {
                        const text = await navigator.clipboard.readText();

                        console.log(text);

                        setCopySuccess(text);
                      }, 1000);
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        borderWidth: 1,

                        borderColor: "green",

                        borderStyle: "solid",

                        height: 60,

                        width: 100,

                        borderRadius: 8,

                        textAlign: "center",
                      }}
                      onClick={async () => {
                        // console.log({ event });

                        navigator.clipboard.readText().then((text) => {
                          setMessage(text);
                        }); // const items = ( //   event.clipboardData || event.originalEvent.clipboardData // ).items; // console.log("itens:", JSON.stringify(items));
                      }}
                    >
                      {" "}
                      <FaAddressBook style={{ color: "green" }} />
                      <p style={{ color: "green" }}>paste</p>{" "}
                    </div>{" "}
                  </div>
                ) : (
                  <div></div>
                )}{" "}
                <Button
                  onClick={() => {
                    ConvertData();
                  }}
                  style={{
                    position: "absolute",

                    top: "90%",

                    right: "-2.5%",

                    transform: "translate(-50%, 0)",

                    background: "green",

                    color: "white",

                    fontWeight: 600,
                  }}
                >
                  GO{" "}
                </Button>{" "}
              </form>
            </Col>
            <Col
              xs={6}
              style={{
                // marginLeft:30
                padding: 0,
                borderLeftStyle: "solid",
                borderLeftColor: "#ddd",
                borderLeftWidth: 3,
              }}
            >
              <form>
                <pre>
                  {" "}
                  {loadingInProgress ? (
                    <div
                      className="loader-container"
                      style={{
                        width: "100%",
                        textAlign: "center",
                        paddingTop: 120,
                      }}
                    >
                      <ClipLoader color={"#ddd"} size={150} />
                    </div>
                  ) : (
                    <div>
                      {loadingBox ? null : (
                        <SyntaxHighlighter
                          language="javascript"
                          style={vscDarkPlus}
                          customStyle={{ height: 450 }}
                        >
                          {result}
                        </SyntaxHighlighter>
                      )}
                    </div>
                  )}
                </pre>
              </form>
            </Col>
          </Row>
          <Disclaimer />
          <AboutUs />
          <Footer />
        </div>
      )}
    </>
  );
}
