import "./form.css";
import React, { useEffect, useState } from "react"; // import firebase from "firebase/app";
import { auth } from "./FirebaseConfiguration";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ReactJsAlert from "reactjs-alert";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
//import "firebase/database";
import { getDatabase, set, ref, child, get } from "firebase/database";

export default function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignIn, setIsSignIn] = useState(true);
  const [user, setUser] = useState(false);
  const [userStatus, setUserStatus] = useState(false);

  const navigator = useNavigate();
  const database = getDatabase();

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  const handleLogin = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log("google User====", result.user);
        // setUser = result.user;
        // if (user) {
        //   navigator("/freeuser");
        // }
      });
  };

  function writeUserData(userId, email) {
    const db = getDatabase();
    set(ref(db, "users/" + userId), {
      email: email,
      // phone: "777hh",
    });
  }
  async function GetUserDetails(userID) {
    // To retrieve data from localStorage

    const dbRef = ref(getDatabase());
    get(child(dbRef, `PremiumUsers/${userID}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          console.log("===DbData", snapshot.val());

          const val = snapshot.val();
          // JSON.stringify(val);
          console.log("firebaseUserEmail", val.status);
          //  setUserStatus(val.status);

          if (val.status == "paid") {
            setUserStatus(true);
            console.log("userstatusif", userStatus);
            navigator("/editor");

            //return true;
          }
        } else {
          console.log("No data available");

          navigator("/freeuser");

          setUserStatus(false);
          // alert("not paid");
          console.log("userstatuselse", userStatus);

          // return false;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (isSignIn) {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          setUser(user);
          console.log(user);
          // To store data in localStorage
          localStorage.setItem("userId", user.uid);
          localStorage.setItem("userEmail", user.email);
          //store data in firebase
          writeUserData(user.uid, user.email);
          GetUserDetails(user.uid);
          // if (abc) {
          //   navigator("/editor");
          // } else {
          //   navigator("/freeuser");
          // }

          // ...
        })
        .catch((error) => {
          <ReactJsAlert
            status={true} // true or false
            type="error" // success, warning, error, info
            title="Hey! this is an alert." // title you want to display
            Close={() => {
              setEmail("");
              setPassword("");
            }} // callback method for hide
          />;
          alert("The password is invalid");
          console.log("error", error);
        });
    } else {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user);
          navigator("/freeuser");
          // ...
        });
    }
  }

  const [toggleHover, setToggleHover] = useState(false);

  const handletoggleHover = () => {
    setToggleHover((prev) => !prev);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 100,
          marginRight: 100,
          justifyContent: "center",
        }}
      >
        <h2>{isSignIn ? "Welcome" : "Sign Up"}</h2>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 50,
          justifyContent: "center",
        }}
      >
        <form onSubmit={handleSubmit}>
          <div class="form">
            <div class="form-item">
              <input
                type="email"
                id="username"
                autocomplete="off"
                required
                value={email}
                onChange={handleEmailChange}
              />
              <label for="username">Username</label>
            </div>

            <div class="form-item">
              <input
                type="password"
                id="password"
                autocomplete="off"
                required
                value={password}
                onChange={handlePasswordChange}
              />
              <label for="password">Password</label>
            </div>
          </div>

          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                flex: "100%",
                maxWidth: 400,
                width: "100%",
                padding: 10,
                marginTop: 10,
                marginRight: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              type="submit"
            >
              {isSignIn ? "Signin" : "Sign Up"}
            </button>
          </div>
          <button
            style={{
              flex: "100%",
              maxWidth: 400,
              width: "100%",
              padding: 10,
              marginTop: 10,
            }}
            onClick={() => setIsSignIn(!isSignIn)}
          >
            {isSignIn ? "Create an Account" : "Sign In Instead"}
          </button>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 15,
              marginRight: 100,
            }}
          >
            <text> Or </text>
          </div>

          <div
            class="form-item"
            onClick={() => {
              navigator("/login");
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: 400,
                marginTop: 10,
              }}
            >
              <img
                src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21'%3E%3Cpath fill='%23f25022' d='M1 1h9v9H1z'/%3E%3Cpath fill='%2300a4ef' d='M1 11h9v9H1z'/%3E%3Cpath fill='%237fba00' d='M11 1h9v9h-9z'/%3E%3Cpath fill='%23ffb900' d='M11 11h9v9h-9z'/%3E%3C/svg%3E"
                style={{
                  justifyContent: "left",
                  alignItems: "flex-start",
                  marginRight: 10,
                  height: 25,
                  width: 25,
                }}
              ></img>
              <Link
                to={"/login"}
                style={
                  toggleHover
                    ? { color: "#8cb1f3ff", textDecoration: "none" }
                    : { color: "#000", textDecoration: "none" }
                }
                onMouseEnter={handletoggleHover}
                onMouseLeave={handletoggleHover}
              >
                Continue with Microsoft Account
              </Link>
            </div>
          </div>
        </form>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 0,
          justifyContent: "center",
          marginRight: 100,
        }}
        class="form-item"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 400,
            marginTop: 10,
          }}
        >
          <img
            src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
            style={{
              justifyContent: "left",
              alignItems: "flex-start",
              marginRight: 10,
              height: 25,
              width: 25,
            }}
          ></img>
          <button
            style={{
              outline: "none",
              background: "none",
              border: "none",
              outline: "none",
            }}
            onClick={handleLogin}
          >
            Login with Google
          </button>{" "}
        </div>
      </div>
      {/* <button onClick={handleLogin}>Login with Google</button> */}

      <br />
      <hr />
    </div>
  );
}
