import { loadStripe } from "@stripe/stripe-js";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      "pk_test_51N3uRiSEKifOKNrzK2LJ3PPONyw0bYdx04jj5B9OcDpIRaOUtfvmgk8Z1pWnEuNPpmK71Un7gi82qEmddiC1d46M00tlCwarfb"
    );
  }
  return stripePromise;
};

function Pricing() {
  async function handleCheckout(e) {
    e.preventDefault();

    const stripe = await getStripe();
    const userIdFromLocalStorage = localStorage.getItem("userId");
    const userEmailFromLocalStorage = localStorage.getItem("userEmail");

    await stripe
      .redirectToCheckout({
        lineItems: [
          {
            price: "price_1N3vaXSEKifOKNrzkIEOS2Dq",
            quantity: 1,
          },
        ],
        mode: "subscription",
        successUrl: `http://localhost:3000/Editor/?session_id={CHECKOUT_SESSION_ID}&alias_id=${userIdFromLocalStorage}`,
        cancelUrl: `http://localhost:3000/editor`,
        //  success_url: `http://localhost:4000/api/checkout/success?true&session_id={CHECKOUT_SESSION_ID}&alias_id=${aliasId}`,
        // cancel_url: `http://localhost:4000/api/checkout/canceled?session_id={CHECKOUT_SESSION_ID}`,
        //http://localhost:3000/success/?session_id={CHECKOUT_SESSION_ID}
        customerEmail: userEmailFromLocalStorage,
      })
      .then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.

        console.log(result);
        if (result.error) {
          alert(result.error.message);
          console.log("Transaction error!");
        } else if (
          result.paymentIntent &&
          result.paymentIntent.status === "succeeded"
        ) {
          // Log success message after successful transaction
          // alert(",fbjfwfnw.f");
          console.log("Transaction successful!");
        }
      });
  }

  return (
    <>
      <h1>React Stripe and the Payment Element</h1>
      <button
        onClick={async (e) => {
          console.log("ssssssss");
          await handleCheckout(e);
        }}
      >
        Pay Now
      </button>
    </>
  );
}

export default Pricing;
