import { Button } from "@mui/material";
import React from "react";
import { FaAngleDown } from "react-icons/fa";

const Disclaimer = () => {
  return (
    <div>
      <div
        style={{
          marginTop: 0,
          marginLeft: 10,
          marginRight: 10,
          fontSize: 16,
          fontWeight: 500,
          fontStyle: "italic",
          color: "#FF0000",
        }}
      >
        When working with code generated by AI, it's crucial to exercise caution
        and perform a thorough review and testing process before utilizing it.
        It's important to remember that this code should augment human expertise
        rather than replace it entirely.
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <Button
          onClick={() => {
            window.scroll({
              top: document.body.offsetHeight,
              left: 0,
              behavior: "smooth",
            });
          }}
          style={{ width: 30, background: "#d1d1d1", fontSize: 25 }}
        >
          <FaAngleDown />
        </Button>
      </div>
    </div>
  );
};

export default Disclaimer;
