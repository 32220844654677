import React from "react";

const AboutUs = () => {
  return (
    <div>
      {" "}
      <div style={{ padding: 60, marginTop: 30, background: "#fff" }}>
        <h3 style={{ textAlign: "center", marginBottom: 20 }}>
          Unlock Your Coding Potential with Amie - The Ultimate AI Companion
        </h3>

        <p style={{ textAlign: "center" }}>
          We understand that your time and efforts are valuable, and that's why
          we've developed a tool that not only enhances your coding skills, but
          also saves you a significant amount of time. Just upload your code to
          the input box and let Amie work her magic.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
