import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
const Logout = () => {
  const { instance } = useMsal();

  useEffect(() => {
    console.log("logout====UE");

    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }, []);
  return (
    <div
      style={{
        margin: 0,
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        justifyContent: "center",
      }}
    >
      <BarLoader color="#FD7F20" />
    </div>
  );
};
export default Logout;
