const FRONTEND_URL = "https://amie.evolutyz.com";

export const msalConfig = {
  auth: {
    clientId: "25031da9-1130-48c0-a0e0-dca6d51ed044",

    authority:
      "https://login.microsoftonline.com/ab09246c-f603-44a6-8ea6-2cf2a56a4179/",

    redirectUri: `${FRONTEND_URL}/auth0/microsoft`,

    postLogoutRedirectUrl: `${FRONTEND_URL}/login`,
  },

  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored

    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.

export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
