import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BarLoader from "react-spinners/BarLoader";

const MicrosoftCallback = () => {
  const { instance, accounts } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const navigator = useNavigate();

  useEffect(() => {
    console.log("Micro====UE");

    async function tok() {
      await instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: accounts,
      });

      console.log("isAuthenticated:", isAuthenticated);
      if (isAuthenticated) {
        navigator("/", { replace: true });
      }
    }

    tok();
  }, []);

  return (
    <div
      style={{
        margin: 0,
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        justifyContent: "center",
      }}
    >
      <BarLoader color="#FD7F20" />
    </div>
  );
};
export default MicrosoftCallback;
